/**
 * Typography
 */

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-LightBETA.eot');
    src: local('Inter Light BETA'), local('../assets/fonts/inter/Inter-LightBETA'),
        url('../assets/fonts/inter/Inter-LightBETA.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-LightBETA.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-LightBETA.woff') format('woff'),
        url('../assets/fonts/inter/Inter-LightBETA.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-LightItalicBETA.eot');
    src: local('Inter Light Italic BETA'), local('../assets/fonts/inter/Inter-LightItalicBETA'),
        url('../assets/fonts/inter/Inter-LightItalicBETA.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-LightItalicBETA.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-LightItalicBETA.woff') format('woff'),
        url('../assets/fonts/inter/Inter-LightItalicBETA.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-Regular.eot');
    src: local('Inter Regular'), local('../assets/fonts/inter/Inter-Regular'),
        url('../assets/fonts/inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-Regular.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-Regular.woff') format('woff'),
        url('../assets/fonts/inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-Italic.eot');
    src: local('Inter Italic'), local('../assets/fonts/inter/Inter-Italic'),
        url('../assets/fonts/inter/Inter-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-Italic.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-Italic.woff') format('woff'),
        url('../assets/fonts/inter/Inter-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-Medium.eot');
    src: local('Inter Medium'), local('../assets/fonts/inter/Inter-Medium'),
        url('../assets/fonts/inter/Inter-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-Medium.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-Medium.woff') format('woff'),
        url('../assets/fonts/inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-MediumItalic.eot');
    src: local('Inter Medium Italic'), local('../assets/fonts/inter/Inter-MediumItalic'),
        url('../assets/fonts/inter/Inter-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-MediumItalic.woff') format('woff'),
        url('../assets/fonts/inter/Inter-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-SemiBold.eot');
    src: local('Inter Semi Bold'), local('../assets/fonts/inter/Inter-SemiBold'),
        url('../assets/fonts/inter/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-SemiBold.woff') format('woff'),
        url('../assets/fonts/inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'inter';
    src: url('../assets/fonts/inter/Inter-SemiBoldItalic.eot');
    src: local('Inter Semi Bold Italic'), local('../assets/fonts/inter/Inter-SemiBoldItalic'),
        url('../assets/fonts/inter/Inter-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/inter/Inter-SemiBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/inter/Inter-SemiBoldItalic.woff') format('woff'),
        url('../assets/fonts/inter/Inter-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'albra';
    src: url('../assets/fonts/albra/Albra-Light.eot');
    src: local('Albra Light'), local('../assets/fonts/albra/Albra-Light'),
        url('../assets/fonts/albra/Albra-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/albra/Albra-Light.woff2') format('woff2'),
        url('../assets/fonts/albra/Albra-Light.woff') format('woff'),
        url('../assets/fonts/albra/Albra-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'albra';
    src: url('../assets/fonts/albra/Albra-Regular.eot');
    src: local('Albra Regular'), local('../assets/fonts/albra/Albra-Regular'),
        url('../assets/fonts/albra/Albra-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/albra/Albra-Regular.woff2') format('woff2'),
        url('../assets/fonts/albra/Albra-Regular.woff') format('woff'),
        url('../assets/fonts/albra/Albra-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'albra';
    src: url('../assets/fonts/albra/Albra-Medium.eot');
    src: local('Albra Medium'), local('../assets/fonts/albra/Albra-Medium'),
        url('../assets/fonts/albra/Albra-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/albra/Albra-Medium.woff2') format('woff2'),
        url('../assets/fonts/albra/Albra-Medium.woff') format('woff'),
        url('../assets/fonts/albra/Albra-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'albra';
    src: url('../assets/fonts/albra/Albra-Semi.eot');
    src: local('Albra Semi'), local('../assets/fonts/albra/Albra-Semi'),
        url('../assets/fonts/albra/Albra-Semi.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/albra/Albra-Semi.woff2') format('woff2'),
        url('../assets/fonts/albra/Albra-Semi.woff') format('woff'),
        url('../assets/fonts/albra/Albra-Semi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

$font-main: "inter", Helvetica, sans-serif;
$font-title: "albra", Georgia, serif;

a {
    color:inherit;
}

.--h1-like, .--h2-like, h2.--real {
    font-family: $font-title;
}

.h2:not(.--real), .--h2-like {
    font-weight:300;
    font-size:rem(36px);
    line-height: rem(42px);

    @include media-breakpoint-up(lg) {
        font-size:rem(42px);
        line-height: rem(48px);
        letter-spacing: -0.012em;
    }

    @include media-breakpoint-up(xl) {
        font-size:rem(54px);
        line-height: rem(60px);
        letter-spacing: -0.018em;
    }

    span {
        //display: block;
        font-weight:500;

        &.--blue {
            color:$light-blue;
        }
    }

    br {
        @include media-breakpoint-down(md) {
            display:none;
        }
    }
}

.subtitle {
    font-size: rem(11px);
    text-transform: uppercase;
    letter-spacing: 0.06em;
    font-weight:600;
    margin-bottom:12px;

    @include media-breakpoint-up(xl) {
        font-size: rem(12px);
    }

    &.--blue {
        color:$light-blue;
    }

    &.--blue-light {
        color:$ultra-light-blue;
    }
}

.slogan {
    font-size:rem(30px);
    line-height:rem(36px);
    letter-spacing: -0.036em;
    font-weight:300;

    @include media-breakpoint-up(xl) {
        font-size:rem(33px);
        line-height:rem(42px);
    }

    span {
      display:block;
      font-weight:600;

      &.--blue {
        color:$light-blue;
      }
    }          
}