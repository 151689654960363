* {
  -webkit-tap-highlight-color: transparent;

  &::selection {
    background:rgba($light-blue,.3);
  }
}

html {
  font-size: rem(16px);
  line-height: rem(24px);

  @include media-breakpoint-up(xxl) {
    font-size: rem(18px);
    line-height: rem(26px);
  }
}

.main-container {
  max-width: 1644px;
  margin:0 auto;
  padding-left:36px;
  padding-right:36px;

  @include media-breakpoint-between(xl, hd) {
    padding-left:60px;
    padding-right:60px;
  }
}

body {
  font-weight:400;
  font-family: $font-main;

  color:$blue;
  background:$ultra-light-gray;

  overflow-y:hidden;
  overflow-x: hidden;

  position: relative;
}

.banner {
  margin-top:120px;

  @include media-breakpoint-up(md) {
    margin-top:180px;
  }

  @include media-breakpoint-up(hd) {
    margin-top:240px;
  }

  .text-container {
    position:relative;
    z-index: 1;

    .title {
      text-align: center;
      font-weight:300;
      font-size:rem(54px);
      line-height: rem(60px);
      letter-spacing: -0.006em;

      @include media-breakpoint-up(sm) {
        font-size:rem(60px);
        line-height: rem(66px);
      }

      @include media-breakpoint-up(md) {
        font-size:rem(72px);
        line-height: rem(72px);
        letter-spacing: -0.012em;
      }
      
      @include media-breakpoint-up(lg) {
        font-size:rem(90px);
        line-height: rem(90px);
        letter-spacing: -0.018em;  
      }

      @include media-breakpoint-up(xl) {
        font-size:rem(120px);
        line-height: rem(120px);
      }

      @include media-breakpoint-up(hd) {
        font-size:rem(144px);
        line-height: rem(150px);
        letter-spacing: -0.024em;
      }

      span {
        font-weight:300;
        display:block;
        font-style: italic;
        font-size:rem(24px);
        margin-top:-18px;
        letter-spacing: -0.03em;

        @include media-breakpoint-up(sm) {
          margin-top:-12px;
        }

        @include media-breakpoint-up(md) {
          font-size:rem(30px);
        }

        @include media-breakpoint-up(lg) {
          font-size:rem(42px);
          margin-top:-18px;
        }

        @include media-breakpoint-up(xl) {
          margin-top:-18px;
        }

        @include media-breakpoint-up(xxl) {
          margin-top:-36px;
        }

        @include media-breakpoint-up(hd) {
          margin-top:-42px;
          font-size:rem(48px);

        }
      }

      &:after {
        content:'';
        width: 60px;
        height: 1px;
        display:block;
        background:$light-blue;
        margin:24px auto;
        
        @include media-breakpoint-up(sm) {
          height: 2px;

        }
      }
    }
  }

  /*
  .imgs-container {
    position:relative;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top:120px;

    @include media-breakpoint-up(md) {
      margin-top:120px;
    }

    @include media-breakpoint-up(lg) {
      margin-top:180px;
    }

    .img-container {
      border-radius:60px;
      overflow: hidden;

      &:first-child {
        margin-top:30px;
        rotate: -1.2deg;
        width: 504px;
        margin-left:-60px;

        @include media-breakpoint-up(md) {
          margin-left:0px;
        }
      }

      &:nth-child(2) {
        margin-top:-60px;
        rotate: 3deg;
        width: 504px;
        margin-right:-60px;

        @include media-breakpoint-up(md) {
          margin-right:0px;
        }
      }

      &:not(:first-child) {
        margin-left:-30px;
      }
  
      img {
        width: 100%;
        display: block;
      }
    }
  }
  */

  .img-container {
    border-radius:30px;
    overflow: hidden;
    width: 100%;
    margin:60px auto 0 auto;
    position:relative;
    height: 200px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    @include media-breakpoint-up(sm) {
      height: 300px;
    }

    @include media-breakpoint-up(md) {
      height: 400px;
    }
    
    @include media-breakpoint-up(lg) {
      //margin-left:0px;
      rotate: -0.6deg;
      border-radius:60px;
      height: 600px;
    }

    @include media-breakpoint-up(xxl) {
      //margin-left:0px;
      width: 1200px;
      height: 800px;
    }

    img {
      width: 100%;
      display:block;

      position: absolute;
      bottom: 0;
      left: 0;
      height: 108%;
      object-fit: cover;
      object-position: center;
      padding: 0;
    }
  }

  .location-container {
    position:relative;
    background:$blue;
    border-radius:30px;
    padding:106px 24px 60px 24px;
    margin:-72px auto 0 auto;
    //width: calc(100% + 24px);
    z-index: -1;

    @include media-breakpoint-up(md) {
      padding:120px 48px 60px 48px;
    }

    @include media-breakpoint-up(lg) {
      width: 852px;
      margin:-72px auto 0 auto;
      padding:60px 36px;
      z-index: 10;
      rotate: 0.6deg;
    }

    h1 {
      font-weight:300;
      color:$white;
      text-align: center;
      font-size:rem(18px);
      line-height:rem(24px);

      @include media-breakpoint-up(md) {
        font-size:rem(18px);
        line-height:rem(24px);
      }

      @include media-breakpoint-up(lg) {
        //font-size:rem(20px);
        //line-height:rem(28px);
        rotate: -0.6deg;
      }

      span {
        font-weight:500;

        @include media-breakpoint-up(lg) {
          //display:block;
        }
      }
    }

    
  }
}

.main-content {
  .intro {
    margin-top:60px;

    @include media-breakpoint-up(md) {
      margin-top:150px;
    }

    h2 {
      font-weight:300;
      font-size:rem(36px);
      line-height: rem(42px);
      letter-spacing: -0.003em;

      @include media-breakpoint-up(md) {
        font-size:rem(42px);
        line-height: rem(48px);
        letter-spacing: -0.006em;
      }

      @include media-breakpoint-up(lg) {
        font-size:rem(48px);
        line-height: rem(54px);
        letter-spacing: -0.012em;
      }

      @include media-breakpoint-up(xxl) {
          font-size:rem(54px);
          line-height: rem(60px);
          letter-spacing: -0.018em;
      }

      br {
        display:none;

        @include media-breakpoint-up(md) {
          display:block;
        }
      }

      span {
        font-weight:500;
      }
    }
    
    .btn {
      margin-top:30px;
    }
  }

  .interlude {
    background:$blue;
    position:relative;
    margin-top:60px;

    &.--right {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;

      @include media-breakpoint-up(lg) {
        border-top-left-radius: 60px;
        border-bottom-left-radius: 60px;
      }

      &:before {
        right: -150%;
      }
  
      &:after {
        @include media-breakpoint-down(lg) {
          right: 50%;
        }
      }

      .interlude-container {
        &:after {
          @include media-breakpoint-up(lg) {
            left:-24%;
            rotate: -90deg;
          }
        }
      }
    }

    &.--left {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;

      @include media-breakpoint-up(lg) {
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
      }

      &:before {
        left: -150%;
      }
  
      &:after {
        @include media-breakpoint-down(lg) {
          left: 50%;
        }
      }

      .interlude-container {
        &:after {
          @include media-breakpoint-up(lg) {
            right:-24%;
            rotate: 90deg;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      margin-top:120px;
    }

    @include media-breakpoint-up(xl) {
      margin-top:138px;
    }

    @include media-breakpoint-up(xxl) {
      margin-top:150px;
    }
   
    &:before {
      content:'';
      position:absolute;
      top:0;
      width: 200%;
      height: 100%;
      background:$blue;
    }

    &:after {
      @include media-breakpoint-down(lg) {
        content:'';
        position:absolute;
        top:0;
        width: 200%;
        height: 100%;
        background:$blue;
        z-index: -1;
      }
    }

    .interlude-container {
      position:relative;
      overflow: hidden;
      padding-top:60px;
      padding-bottom:60px;

      @include media-breakpoint-up(md) {
        padding-top:120px;
        padding-bottom:120px;
      }

      @include media-breakpoint-up(xl) {
        padding-top:150px;
        padding-bottom:150px;
      }

      @include media-breakpoint-up(xxl) {
        padding-top:180px;
        padding-bottom:180px;
      }

      &:after {
        @include media-breakpoint-up(lg) {
          content:'';
          position:absolute;
          top:0;
          width: 72%;
          height: 100%;
          background-image: url('/assets/img/icons/shape.svg');
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: center;
          background-size: 100%;
          z-index: 0;
          opacity: 0.06;
        }
      }

      .title {
        color:$white;
      }
  
      .text-container {
        p {
          color:$white;
        }
      }
    }
  }

  .intro,
  .interlude {
    .section-container {
      margin-left:auto;
      margin-right:auto;

      @include media-breakpoint-up(lg) {
        width: 72%;
      }
      
      @include media-breakpoint-up(xl) {
        width: 60%;
      }

      .text-container {
        margin-left:60px;
        margin-top:12px;

        @include media-breakpoint-up(md) {
          margin-left:90px;
          width: 72%;
          margin-top:30px;
        }

        @include media-breakpoint-up(lg) {
          margin-left:150px;
          width: 510px;
        }
  
        p {
          & + p {
            margin-top:24px;
          }
        }
      }
    }
  }

  .steps {
    margin-top:60px;

    @include media-breakpoint-up(md) {
      margin-top:120px;
    }

    @include media-breakpoint-up(xl) {
      margin-top:180px;
    }

    @include media-breakpoint-up(hd) {
      margin-top:240px;
    }

    .slogan-container {   
      text-align: center;

      @include media-breakpoint-up(md) {
        float:left;
        width: 48%;
        position:relative;
        z-index: 5;
        text-align:left;
      }

      @include media-breakpoint-up(lg) {
        width: 36%;
      }

      @include media-breakpoint-up(xl) {
        width: 30%;
      }

      .slogan {
        pointer-events: none;

        br {
          display:none;

          @include media-breakpoint-up(md) {
            display:block;
          }
        }
      }
    }

    .swiper-container {
      margin-top:24px;
      position:relative;

      @include media-breakpoint-up(md) {
        display: flex;
      }

      .swiper-buttons { 
        display:flex;
        justify-content: center;
        z-index: 10;
        margin-bottom:-18px;
        position:relative;

        @include media-breakpoint-up(md) {
          justify-content: start;
          top:-18px;
          left:24px;
          position:absolute;
        }

        @include media-breakpoint-up(lg) {
          left:54px;
        }

        @include media-breakpoint-up(xl) {
          //margin-top:24px;
          //top:-18px;
          //left:54px;
        }
      }
      
      @include media-breakpoint-up(md) {
        width: 52%;
        margin-top:0px;
      }

      @include media-breakpoint-up(lg) {
        width: 64%;
      }

      @include media-breakpoint-up(xl) {
        width: 70%;
      }

      .swiper-wrapper {
        //align-items: stretch;
    
        .swiper-slide {
          overflow: hidden;
          position:relative;
          
          @include media-breakpoint-up(md) {
            min-height: 100%;
          }

          &:after {
            content:'';
            position:absolute;
            bottom:-200px;
            height: 100%;
            width: calc(100% - 30px);
            left:0;
            background:$white;
            z-index:-1
          }

          .swiper-slide-container {
            padding:42px 30px 36px 30px;
            margin-right:12px;
            background:$white;
            height: 100%;

            @include media-breakpoint-up(lg) {
              padding:72px 60px;
              margin-right:30px;
            }

            p {
              &.title {
                font-family: $font-title;
                font-weight:500;
                margin-bottom:12px;
                font-size:rem(19px);

                span {
                  color:$light-blue;
                  font-size:rem(13px);
                }
              }
              
              &:not(.title) {
                @include media-breakpoint-up(xxl) {
                  font-size:rem(14px);
                  line-height: rem(26px);
                }
              }
            }
          }
        }

        .swiper-slide-shadow {
          background:$white;
        }
      }
    }
  }

  .columns {
    background:$white;
    margin-left:-36px;
    width: calc(100% + 72px);
    padding-left:36px;
    padding-right:36px;

    @include media-breakpoint-up(lg) {
      margin-left:0;
      width: 100%;
      padding-left:0;
      padding-right:0;
      border-bottom-left-radius: 60px;
      margin-top:-60px;
      padding-top:60px;
      position:relative;
      z-index: -1;  
    }

    &:after {
      @include media-breakpoint-up(lg) {
        content:'';
        position:absolute;
        top:0;
        width: 200%;
        right:-150%;
        height: 100%;
        background:$white;
        z-index: -1;
      }
    }

    .boxes-container {
      margin-left:auto;
      margin-right:auto;
      padding-top:30px;
      padding-bottom:30px;
      display:flex;
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
        padding-top:60px;
        padding-bottom:60px;
      }

      @include media-breakpoint-up(lg) {
        width: 72%;
      }

      @include media-breakpoint-up(xl) {
        padding-top:90px;
        padding-bottom:90px;
        width: 60%;
      }

      @include media-breakpoint-up(xxl) {
        padding-top:120px;
        padding-bottom:120px;
      }

      .box-container {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(sm) {
          padding-top:42px;
          padding-bottom:42px;
        }

        @include media-breakpoint-up(sm) {
          width: 50%;
        }

        &:nth-child(odd) {
          @include media-breakpoint-up(sm) {
            padding-right:36px;
            border-right:1px solid $gray;
          }
        }

        &:nth-child(even) {
          @include media-breakpoint-up(sm) {
            padding-left:36px;
          }
        }

        &:nth-child(1n + 3) {
          @include media-breakpoint-up(sm) {
            border-top:1px solid $gray;
          }
        }

        &:not(:first-child) {
          @include media-breakpoint-down(sm) {
            border-top:1px solid $gray;
          }
        }

        p {
          &.title {
            font-family: $font-title;
            font-weight:500;
            margin-bottom:12px;
            font-size:rem(19px);

            span {
              color:$light-blue;
              font-size:rem(13px);
            }
          }
          
          &:not(.title) {
            @include media-breakpoint-up(xxl) {
              font-size:rem(14px);
              line-height: rem(26px);
            }
          }
        }
      }
    } 
  }

  .faq {
    margin-top:60px;

    @include media-breakpoint-up(md) {
      margin-top:120px;
    }

    @include media-breakpoint-up(xl) {
      margin-top:180px;
    }

    @include media-breakpoint-up(hd) {
      margin-top:240px;
    }
    
    .header {
      text-align: center;
    }

    .accordion {
      margin:42px auto 0 auto;

      @include media-breakpoint-up(lg) {
        width: 72%;
      }

      @include media-breakpoint-up(xl) {
        width: 60%;
      }

      .accordion-item {
        transition: all .2s;
        margin-left:-36px;
        width:calc(100% + 72px);

        @include media-breakpoint-up(md) {
          border-radius:42px;
          margin-left:0;
          width:100%;
        }

        .accordion-question {
          cursor: pointer;
          user-select: none;
          font-size:rem(18px);
          font-weight:600;
          display:flex;
          justify-content: space-between;
          position:relative;
          padding-bottom: 12px;

          p {
            width: 100%;
            padding-right:60px;
          }

          .minus-plus-btn {
            position:absolute;
            top:-3px;
            right: 0;
            pointer-events: none;
          }
        }

        .accordion-content {
          height: 0;
          overflow: hidden;
          width: 90%;

          p {
            @include media-breakpoint-up(xxl) {
              font-size:rem(14px);
              line-height:rem(24px);
            }

            &.list-title {
              font-weight:500;
              margin-top:12px;
            }
          }

          ul {
            li {
              font-size:rem(14px);
            }
          }
  
          &.expanded {
            //height: 0;
            //overflow: hidden;
          }
        }

        &.active {
          background:$white;
          padding:30px;

          @include media-breakpoint-up(md) {
            padding:30px 42px;
          }

          &:before {
            opacity:0;   
          }

          & + .accordion-item {
            &:before {
              opacity:0;
            }
          }

          .accordion-question {
            width: 100%;

            p {
              @media (hover: hover) {
                &:hover {
                  & + .minus-plus-btn {
                    background:$flash-blue;

                    .spans-container {
                      span {
                        background:$white;
                      }
                    }
                  }
                }
              }
            }

            .minus-plus-btn {
              background:$blue;
  
              .spans-container {
                  span {
                    background:$white;
  
                    &:nth-child(2) {
                      margin-top:-12px;
                      margin-right:-5px;
                      opacity: 0;
                  }
    
                  &:nth-child(4) {
                      margin-bottom:-12px;
                      margin-left:-5px;
                      opacity: 0;
                  }
                }
              }
            }
          }
        }

        &:not(:first-child) {
          position:relative;

          &:before {
            content:'';
            background:$gray;
            width: 100%;
            height:1px;
            position:absolute;
            top:0;
            left:0;
          }
        }

        &:not(.active) {
          padding:30px 30px 18px 30px;

          @include media-breakpoint-up(md) {
            padding:30px 42px 18px 42px;
          }

          .accordion-question {
            p {
              @media (hover: hover) {
                &:hover {
                & + .minus-plus-btn {
                    background:$flash-blue;
    
                    .spans-container {
                      span {
                        background:$white;
                      }
                    }
                  }
                }
              }
            }
            
            .minus-plus-btn {
              .spans-container {
                  span {
                    &:nth-child(2) {
                        margin-top:-12px;
                        margin-right:-5px;
                    }
      
                    &:nth-child(4) {
                        margin-bottom:-12px;
                        margin-left:-5px;
                    }
                }
              }
            }
          }
        }
      }
    }

    .footer {
      margin-top:30px;
      text-align: center;

      @include media-breakpoint-up(md) {
        margin-top:60px;
      }

      .btn {
        margin-top:24px;
      }
    }
  }

  .testimonials {
    background:$light-gray;
    padding-top:60px;
    padding-bottom:60px;
    clear: both;
    margin-top:60px;

    @include media-breakpoint-up(md) {
      margin-top:120px;
      padding-top:90px;
      padding-bottom:90px;
    }

    @include media-breakpoint-up(xl) {
      margin-top:180px;
    }

    @include media-breakpoint-up(hd) {
      margin-top:240px;
      padding-top:120px;
      padding-bottom:120px;
    }

    .testimonials-container {
      @include media-breakpoint-up(xl) {
        display:flex;
      }

      & > *  {
        max-width: 100%;
      }

      .flex-container {
        @include media-breakpoint-up(xl) {
          display: initial; //content?
        }
        
        .slogan-container {
          @include media-breakpoint-up(xl) {
            float:left;
            display:flex;
            align-items: center;
            height: 100%;
          }
  
          .slogan {
            position:relative;
            flex-shrink: 0;
            text-align:center;
            margin-bottom:30px;

            &:after {
              height: 2px;

              @include media-breakpoint-down(sm) {
                height: 1px;
              }

              @include media-breakpoint-down(xl) {
                content:'';
                width: 60px;
                margin:18px auto;
                display:block;
                background:$blue;
              }
            }

            @include media-breakpoint-up(xl) {
              padding-right:60px;
              margin-right:60px; 
              border-right:2px solid $blue;
              text-align:right;
              margin-bottom:0px;
            }

            @include media-breakpoint-up(hd) {
              margin-right:90px; 
              text-align:right;
            }
          }
        }
      }
  
      .swiper-container {
        display:flex;
        align-items: center;
        position:relative;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
          justify-content: flex-start;
          flex-direction: row;
        }

        .swiper-wrapper {
          //height: 100% !important;
        }
  
        .testimonial-container {
          .swiper-slide-container {
              display:flex;
              align-items: center;  
              flex-direction: column;
              order:0;

            @include media-breakpoint-up(xl) {
              justify-content: flex-start;
              flex-direction: row;
            }
   
            .left {
              text-align: center;
              flex-shrink: 0;
              width:186px;
              margin-top:0px;
    
              .img-container {
                overflow: hidden;
                border-radius:120px;
                width:186px;
                height: 240px;
    
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
    
            .right {
              position:relative;
              text-align: center;
              margin-top:24px;

              @include media-breakpoint-up(xl) {
                margin-left:30px;
                margin-top:0px;
                text-align: left;
              }

              @include media-breakpoint-up(hd) {
                margin-left:60px;
              }

              p {
                position: relative;
                z-index: 5;

                @include media-breakpoint-up(xxl) {
                  font-size:rem(14px);
                  line-height:rem(24px);
                }
              }
            }
          }
        }
  
        .swiper-buttons { 
          display:flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          width: 186px;
          margin-top:24px;
          order:2;

          @include media-breakpoint-up(xl) {
            bottom:-18px;
            left:0;
            position: absolute;
            margin-top:0;
          }
        }
      }
    }
  }
}

// APPEAR ANIMATION
.section {
  &.--first {
    opacity: 0;
  }

  &.--fade {
    &:not(.--multi) {
      //opacity: 0;
    }

    &.--multi {
      .stagger-item {
        //opacity: 0;
      }
    }
  }

  &.--translate {
    &.--multi {
      .stagger-item {
        //opacity: 0;
        //transform: translateY(30px);
      }
    }
  }
}

// COOKIES
#cc--main {
  #cm {
    #c-inr {
      display:flex;
      align-items: center;

      #c-txt {
        font-weight: 500;
        padding-right:15px;
        font-size:rem(12px);
      }

      #c-bns {
        margin-top:0px;
        font-size:rem(12px)
      }
    }
  }
}

// LEGAL & ERROR
.page-error,
.page-legal {
  .banner {
    margin-top:180px;
  }

  .main-content {
    margin-bottom:240px;

    .btn-container {
      margin: 48px auto 0 auto;
      text-align: center;
    }
  }
}

.page-error {
  .banner {
    text-align: center;
    margin-bottom:24px;

     p {
      font-size:rem(60px);
      font-weight:700;
      display: block;
    }
  }

  .main-content {
    text-align: center;
    margin-top:48px;
  }
}

.page-legal {
  .banner {
     p {
      font-weight:700;
      display: block;
      font-size:rem(30px);
      line-height:rem(36px);
      letter-spacing: -0.03em;
    }
  }

  .main-container {
    @include media-breakpoint-up(md) {
      width: 36%;
    }
  }

  .welcome {
    margin-top:24px;
    margin-bottom:36px;
    width: 84%;

    p {
      & + p {
        margin-top:12px;
      }
    }
  }

  .title {
    margin-top:30px;
    font-weight:600;
    margin-bottom:6px;

    @include media-breakpoint-up(xxl) {
      font-size:rem(14px);
    }
  }

  .body {
    ul {
      padding-left:33px;

      @include media-breakpoint-up(xxl) {
        font-size:rem(14px);
      }
    }

    p {
      @include media-breakpoint-up(xxl) {
        font-size:rem(14px);
      }
    }
    
    a {
      transition: all .2s;
      color:$blue;
      font-weight:500;

      @media (hover: hover) {
        &:hover {
          color:$flash-blue;
        }
      }
    }
  }
}


//POPUP
#popup {
  position:fixed;
  top:0;
  left: 0;
  display:flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  width: 100%;
  height: 100%;  
  background:black;
  opacity: 0;
  transition: all .2s;
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    padding-left:100px;
    padding-right:100px;
    background:rgba(black, 0.9);
  }

  .video-container {
    position:relative;
    text-align: center;

    .icon-container {
      position:absolute;
      top:-100px;
      left:calc(50% - 25px);
      background:$white;
      width: 50px;
      height: 50px;
      display:flex;
      align-items: center;
      justify-content: center;
      border-radius:100%;
      cursor: pointer;
      transition: all .2s;
      z-index: 9999;
      opacity: 0;
      transition: all .2s ease 2s;

      @include media-breakpoint-up(lg) {
        top:-25px;
      }

      &:hover {
        //opacity: 0.5;
        transform: scale(1.1);

        svg {
          transform: rotate(90deg) scale(0.9);
        }
      }
  
      svg {
        display: block;
        //fill:$white;
        transition: all .2s;
      }
    }
  
    video {
      @include media-breakpoint-up(md) {
        height: 80%;
      }
    }
  }
}


#popupPlay {
  //position:fixed;
  //right:40px;
  //bottom:40px;
  cursor: pointer;
  position:relative;
  z-index: 9998;

  //position:absolute;
  //bottom:-70px;
  margin:-40px auto 0 auto;
  background:$gray;
  width: 80px;
  height: 80px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:100%;
  cursor: pointer;
  transition: all .2s;
  z-index: 9999;

  &:hover {
    //opacity: 0.5;
    transform: scale(1.1);
    background:$white;

    svg {
      transform: scale(0.9);
    }
  }

  svg {
    display: block;
    //fill:$white;
    transition: all .2s;
  }
}

body {
  &.popup-open {
    #popup {
      opacity: 1;
      pointer-events: auto;

      .icon-container {
        opacity: 1;
      }
    }
  }
}