/**
 * Buttons
*/

.btn {
    display: inline-block;

    &.--rounded {
        border-radius:30px;
        text-transform: uppercase;
        font-weight:700;
        transition: all .2s;
        padding:6px 24px;
        font-size:rem(11px);
        letter-spacing: 0.12em;
        position:relative;
        transition: all .2s;
        
        &:before {
            //content:'';
            position:absolute;
            top:0;
            left:0;
            width: 0%;
            height: 100%;
            border-radius:30px;
            transition: all .2s;
            background:$light-blue;
            //transform: translateX(-100%);
            z-index: 0;
        }

        span {
            position:relative;
            z-index: 5;
        }
        
        &:hover {
            background:$light-blue;
            color:$blue;

            &:before {
                width: 100%;
                //transform: translateX(0%);
            }
        }

        &.--blue {
            background:$light-blue;
            color:$white;

            &:hover {
                background:$flash-blue;

                &:before {
                    width: 100%;
                    //transform: translateX(0%);
                }
            }
        }
    }
}

.ben-button {
    border-radius:100%;
    width: 36px;
    height: 36px;
    display:flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;

    &:not(.--blue) {
        background:$white;

        .spans-container {
            span {
                background:$blue;
            }
        }
    }

    &.--blue {
        background:$blue;

        .spans-container {
            span {
                background:$white;
            }
        }
    }

    .spans-container {
        display:flex;
        align-items: center;
        justify-content: center;

        span {
            width: 3px;
            height: 3px;
            border-radius:100%;
            margin:2px;
            transition: all .2s;
        }
    }

    @media (hover: hover) {
        &:hover {
            background:$flash-blue;

            .spans-container {
                span {
                    background:$white;
                }
            }
        }
    }

    &.swiper-button {
        cursor: pointer;

        &.swiper-button-disabled {
            opacity:.66;
            transform:scale(0.9);
            pointer-events: none;
        }

        &.swiper-button-prev {
            margin-right:2px;

            @media (hover: hover) {
                &:hover {
                    .spans-container {
                        //margin-right:4px;
                    }
                }
            }

            .spans-container {
                margin-right:2px;
            
                span { 
                    &:nth-child(2) {
                        margin-top:-6px;
                    }

                    &:nth-child(3) {
                        margin-left:-5px;
                        margin-bottom:-6px;
                    }
                }
            }
        }

        &.swiper-button-next {
            margin-left:2px;

            @media (hover: hover) {
                &:hover {
                    .spans-container {
                        //margin-right:-4px;
                    }
                }
            }

            .spans-container {
                margin-right:-2px;

                span {
                    &:first-child {
                        margin-bottom:-6px;
                    }

                    &:nth-child(2) {
                        margin-top:-6px;
                        margin-left:-5px;
                    }
                }
            }
        }
    }
}

button,
input {
    background:none;
    border:0px;
    padding:0px;
    margin:0px;
    //line-height: 100%;
    cursor: pointer;
    color:inherit;
}