/**
 * Footer
 */

footer {
    background:$blue;
    color:$white;
    padding-top:60px;
    padding-bottom:60px;

    margin-top:60px;

    @include media-breakpoint-up(md) {
        padding-top:120px;
        padding-bottom:120px;
        margin-top:120px;
    }

    @include media-breakpoint-up(lg) {
        //padding-top:180px;
        //padding-bottom:180px;
    }

    @include media-breakpoint-up(xl) {
        padding-top:180px;
        padding-bottom:180px;
        margin-top:180px;
    }

    @include media-breakpoint-up(hd) {
        margin-top:240px;
    }

    .footer-container {
        text-align: center;
        
        @include media-breakpoint-up(md) {
            //text-align: left;
            //display:flex;
            //align-items: end;
            //justify-content: space-between;
        }

        .top {
            .brand {
                svg {
                    height: auto;
                    width: 240px;

                    @include media-breakpoint-up(xl) {
                        width: 300px;
                    }

                    path {
                        fill:$white;
                    }
                }
            }

            .faces-icons {
                max-width:600px;
                margin:30px auto 0px auto;

                @include media-breakpoint-up(md) {
                    margin:90px auto 0px auto;
                }

                svg {
                    max-width:600px;
                }

                ul {
                    padding-left:0px;
                    list-style: none;
                    display:flex;
                    justify-content: space-between;

                    @include media-breakpoint-up(md) {
                        padding-left:15px;
                        padding-right:15px;
                        margin-top:12px;
                    }

                    
                    li {
                        color:$light-blue;
                        font-weight:500;
                    }
                }
            }

            p {
                margin-bottom:6px;
                font-family: $font-title;
                font-weight: 100;
                font-weight:300;
                font-size:rem(16px);
                line-height: rem(24px);
                margin-top:30px;
                margin-bottom:12px;
                
                @include media-breakpoint-up(sm) {
                    font-size:rem(18px);
                }

                @include media-breakpoint-up(md) {
                    margin-top:72px;
                    margin-bottom:12px;
                }

                @include media-breakpoint-up(lg) {
                    font-size:rem(21px);
                    margin-bottom:30px
                }

                @include media-breakpoint-up(xl) {
                }
            }

            .big-link {
                font-family: $font-title;
                font-weight:500;
                transition: all .2s;
                font-size:rem(42px);
                line-height: rem(42px);
                letter-spacing: -0.012em;

                @include media-breakpoint-up(lg) {
                    font-size:rem(60px);
                    line-height: rem(60px);
                    letter-spacing: -0.018em;
                }

                @include media-breakpoint-up(xl) {
                    font-size:rem(90px);
                    line-height: rem(90px);
                    letter-spacing: -0.024em;
                }

                @media (hover: hover) {
                    &:hover {
                        color:$ultra-light-blue;
                        letter-spacing: 0em;
                    }
                }
            }
        }

        .bottom {
            display:flex;
            font-size:rem(12px);
            margin-top:30px;
            justify-content: center;

            @include media-breakpoint-up(sm) {
                font-size:rem(14px);
            }

            @include media-breakpoint-up(md) {
                margin-top:60px;
                //justify-content: flex-end;
            }

            ul {
                list-style: none;
                padding-left:0px;
                margin-right:5px;

                li {
                    display:inline;

                    a {
                        font-weight:600;
                        transition: all .2s;

                        @media (hover: hover) {
                            &:hover {
                                color:$light-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}