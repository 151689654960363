/**
 * Colors
 */

// Default text color

//$brun: #ad9a90;
$blue: #1f3d6b;
$ultra-light-blue:#7898cc;
$light-blue: #6382b5;
$flash-blue: #5985cc;

$white: #ffffff;
$black: #0b262a;
$ultra-light-gray: #fbfbfb;
$light-gray:#f8f8f8;
$gray:#f0f0f0;

// Theme colors
$color-text: $blue !default;
$color-primary: $blue;
$color-secondary: $light-blue;


// Links
$color-link-color: $light-blue !default;
$color-link-color-hover: darken($color-link-color, 20%) !default;
//$color-link-color-hover: color.adjust($color, $lightness: -20%) !default;


