/**
 * Navbar
 */
header {
    position:relative;

    &:before {
        content:'';
        position:absolute;
        top:-30px;
        left:0;
        width: 100%;
        height: 980px;
        background-image: url('/assets/img/icons/shape.svg');
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: top;
        background-size: 150%;
        z-index: -1;

        @include media-breakpoint-up(lg) {
            background-size: contain;
        }

        @include media-breakpoint-up(lg) {
            top:-160px;
        }
    }

    .navbar {
        padding-top:120px;

        @include media-breakpoint-up(sm) {
            padding-top:150px;
        }

        .brand {
            display:block;
            text-align: center;
            
            svg {
                width: 90%;

                @include media-breakpoint-up(sm) {
                    width: 356px;
                }
    
                path {
                    fill:$blue;
                }
            }
       }
    }
}