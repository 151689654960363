
/* CONVERT PX TO REM */
@use 'sass:math';

@function rem($size) {
  @return math.div($size, 16px) * 1rem;
}
 
@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

* {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
}

// Configuration
/*
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/utilities";
*/

// Layout & components
/*
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
*/


// Swiper
@import "node_modules/swiper/swiper.scss";
@import "node_modules/swiper/modules/effect-fade/effect-fade.scss";
@import "node_modules/swiper/modules/effect-creative/effect-creative.scss";


// Main
@import "settings/_breakpoints.scss";
@import "settings/_colors.scss";
@import "settings/_global.scss";
@import "settings/_spacing.scss";
@import "settings/_typography.scss";

@import "generic/_box-sizing.scss";
@import "generic/_normalize.scss";
@import "generic/_print.scss";
@import "elements/_cookies.scss";
@import "elements/_links.scss";
@import "elements/_media.scss";

@import "components/_buttons.scss";
@import "components/footer.scss";
@import "components/navbar.scss";


@import 'shame';
@import 'styles';