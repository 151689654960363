/**
 *  Breakpoints
 */
 
// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
    $prev-key: null;
    $prev-num: null;
    @each $key, $num in $map {
      @if $prev-num == null or unit($num) == "%" or unit($prev-num) == "%" {
        // Do nothing
      } @else if not comparable($prev-num, $num) {
        @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
      } @else if $prev-num >= $num {
        @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
      }
      $prev-key: $key;
      $prev-num: $num;
    }
  }
  
  
  // Starts at zero
  // Used to ensure the min-width of the lowest breakpoint starts at 0.
  @mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
    @if length($map) > 0 {
      $values: map-values($map);
      $first-value: nth($values, 1);
      @if $first-value != 0 {
        @warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
      }
    }
  }
  
  
  // Grid breakpoints
  //
  // Define the minimum dimensions at which your layout will change,
  // adapting to different screen sizes, for use in media queries.
  
  // scss-docs-start grid-breakpoints
  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    hd: 1600px
  ) !default;
  // scss-docs-end grid-breakpoints
  
  @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
  @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");
  
  
  // Grid containers
  //
  // Define the maximum width of `.container` for different screen sizes.
  
  // scss-docs-start container-max-widths
  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
  ) !default;
  // scss-docs-end container-max-widths
  
  @include _assert-ascending($container-max-widths, "$container-max-widths");
  
  
  // Grid columns
  //
  // Set the number of columns and specify the width of the gutters.
  
  $grid-columns:                12 !default;
  $grid-gutter-width:           1.5rem !default;
  $grid-row-columns:            6 !default;
  
  // Container padding
  
  $container-padding-x: $grid-gutter-width !default;
  
  // Breakpoint viewport sizes and media queries.
  //
  // Breakpoints are defined as a map of (name: minimum width), order from small to large:
  //
  //    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px)
  //
  // The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.
  
  // Name of the next breakpoint, or null for the last breakpoint.
  //
  //    >> breakpoint-next(sm)
  //    md
  //    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    md
  //    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl xxl))
  //    md
  @function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @if not $n {
      @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
    }
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
  }
  
  // Minimum breakpoint width. Null for the smallest (first) breakpoint.
  //
  //    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    576px
  @function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
  }
  
  // Maximum breakpoint width.
  // The maximum value is reduced by 0.02px to work around the limitations of
  // `min-` and `max-` prefixes and viewports with fractional widths.
  // See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
  // Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
  // See https://bugs.webkit.org/show_bug.cgi?id=178261
  //
  //    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    767.98px
  @function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $max: map-get($breakpoints, $name);
    @return if($max and $max > 0, $max - .02, null);
  }
  
  // Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
  // Useful for making responsive utilities.
  //
  //    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    ""  (Returns a blank string)
  //    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
  //    "-sm"
  @function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
  }
  
  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  @mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
      @media (min-width: $min) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  @mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
      @media (max-width: $max) {
        @content;
      }
    } @else {
      @content;
    }
  }
  
  // Media that spans multiple breakpoint widths.
  // Makes the @content apply between the min and max breakpoints
  @mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($lower, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($upper, $breakpoints) {
        @content;
      }
    }
  }
  
  // Media between the breakpoint's minimum and maximum widths.
  // No minimum for the smallest breakpoint, and no maximum for the largest one.
  // Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
  @mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min:  breakpoint-min($name, $breakpoints);
    $next: breakpoint-next($name, $breakpoints);
    $max:  breakpoint-max($next, $breakpoints);
  
    @if $min != null and $max != null {
      @media (min-width: $min) and (max-width: $max) {
        @content;
      }
    } @else if $max == null {
      @include media-breakpoint-up($name, $breakpoints) {
        @content;
      }
    } @else if $min == null {
      @include media-breakpoint-down($next, $breakpoints) {
        @content;
      }
    }
  }